import React, { useRef, useEffect, useState } from "react";
import Swiper from "swiper";
import { Controller, Navigation } from "swiper/modules";

import Icon from "./Icon.js";

export default ({ list }) => {
  const banner = useRef();
  const background = useRef();
  const content = useRef();
  const [load, setLoad] = useState(false);

  useEffect(() => {
    const $bg = new Swiper(background.current, {
      speed: 600,
    });
    const swiper = new Swiper(content.current, {
      init: false,
      speed: 600,
      modules: [Controller, Navigation],
      navigation: {
        prevEl: ".swiper-button-prev",
        nextEl: ".swiper-button-next",
        disabledClass: "!opacity-0",
      },
      controller: {
        control: [$bg],
      },
    });

    swiper.on("afterInit", () => {
      const img = banner.current.querySelectorAll("img[data-src]");
      img.forEach((img) => {
        const src = img.getAttribute("data-src");
        img.src = src;
        img.classList.add("!opacity-100");
      });
    })

    swiper.init();

    window.addEventListener('load', () => {
      setLoad(true);
    });
  }, []);

  const imgAlt = (image) => {
    const img = image.split("/");
    const result = img.pop();
    return result;
  };

  return (
    <div ref={banner} className="relative z-10 pb-[10%]">
      <div
        ref={background}
        className="swiper"
        style={{ clipPath: "ellipse(130% 100% at 50% 0%)" }}
      >
        <div className="swiper-wrapper">
          {list.map((item, index) => {
            const attr = index ? { "data-src": item.bg } : { src: item.bg };
            return (
              <div
                className="swiper-slide"
                key={`banner-bg-${item.id}-${index}`}
              >
                <div className="relative aspect-square md:aspect-[3/2] lg:aspect-[2/1]">
                  <figure className="absolute inset-0">
                    <img
                      loading="lazy"
                      {...attr}
                      className="w-full h-full object-cover block"
                      alt={imgAlt(item.bg)}
                    />
                  </figure>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div ref={content} className={`${load ? 'visible' : 'invisible'} swiper absolute inset-x-0 bottom-0 !h-auto`}>
        <div className="swiper-wrapper">
          {list.map((item, index) => {
            return (
              <div
                className="swiper-slide"
                key={`banner-content${item.id}-${index}`}
              >
                <div className="relative">
                  <div className="flex flex-col items-center">
                    <div data-aos="zoom-in" style={{backgroundImage: `url(${item.img})` }}  className="w-[85%] md:w-[76%] aspect-[1082/860] bg-contain bg-bottom">
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        <span className="swiper-button-prev absolute top-[40%] -translate-y-full flex w-5 md:w-12 left-0 cursor-pointer aspect-[1/2] z-10 bg-white/[0.8] text-cardinal-600 hover:bg-cardinal-600 hover:text-white transition duration-500">
          <button className="m-auto w-4/5 md:w-2/3" type="button" title="Prev">
            <Icon icon="arrowLeft" />
          </button>
        </span>
        <span className="swiper-button-next absolute top-[40%] -translate-y-full flex w-5 md:w-12 right-0 cursor-pointer aspect-[1/2] z-10 bg-white/[0.8] text-cardinal-600 hover:bg-cardinal-600 hover:text-white transition duration-500">
          <button className="m-auto w-4/5 md:w-2/3" type="button" title="Next">
            <Icon icon="arrowRight" />
          </button>
        </span>
      </div>
    </div>
  );
};
