import React from "react"
import * as Icons from "../icons.js"
import {stringTemplate} from "../utils/index.js"
const Icon = ({icon}) => {
	const {content, viewBox} = Icons[icon];
	return (
		<svg className="aspect-square w-full" viewBox={viewBox}>
			{stringTemplate(content)}
		</svg>
	);
}

export default Icon;