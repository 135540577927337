export const arrowLeft = {
  viewBox: '0 0 320 512',
  content: '<path fill="currentColor" d="M41.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.3 256 246.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z" />'
}

export const arrowRight = {
  viewBox: '0 0 320 512',
  content: '<path fill="currentColor" d="M278.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-160 160c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L210.7 256 73.4 118.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l160 160z" />'
}

export const arrowDown = {
  viewBox: '0 0 512 512',
  content: '<path fill="currentColor" d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z"/>'
}

export const bars = {
  viewBox: '0 0 448 512',
  content: '<path fill="currentColor" d="M0 96C0 78.3 14.3 64 32 64H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 128 0 113.7 0 96zM0 256c0-17.7 14.3-32 32-32H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32zM448 416c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H416c17.7 0 32 14.3 32 32z"/>'
}

export const xmark = {
  viewBox: '0 0 384 512',
  content: '<path fill="currentColor" d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"/>'
}

export const instagram = {
  viewBox: '0 0 50 50',
  content: '<path fill="currentColor" d="M14.0101 0H34.3005C42.0302 0 48.3105 6.5 48.3105 14.5V35.5C48.3105 39.3456 46.8345 43.0338 44.2071 45.7531C41.5797 48.4723 38.0162 50 34.3005 50H14.0101C6.28037 50 0 43.5 0 35.5V14.5C0 10.6544 1.47606 6.96623 4.10345 4.24695C6.73085 1.52767 10.2944 0 14.0101 0ZM13.5269 5C11.2207 5 9.00882 5.94821 7.37802 7.63604C5.74723 9.32387 4.83105 11.6131 4.83105 14V36C4.83105 40.975 8.72005 45 13.5269 45H34.7836C37.0899 45 39.3017 44.0518 40.9325 42.364C42.5633 40.6761 43.4795 38.3869 43.4795 36V14C43.4795 9.025 39.5905 5 34.7836 5H13.5269ZM36.8368 8.75C37.6376 8.75 38.4056 9.07924 38.9718 9.66529C39.5381 10.2513 39.8562 11.0462 39.8562 11.875C39.8562 12.7038 39.5381 13.4987 38.9718 14.0847C38.4056 14.6708 37.6376 15 36.8368 15C36.036 15 35.268 14.6708 34.7017 14.0847C34.1355 13.4987 33.8174 12.7038 33.8174 11.875C33.8174 11.0462 34.1355 10.2513 34.7017 9.66529C35.268 9.07924 36.036 8.75 36.8368 8.75ZM24.1553 12.5C27.3585 12.5 30.4305 13.817 32.6954 16.1612C34.9604 18.5054 36.2329 21.6848 36.2329 25C36.2329 28.3152 34.9604 31.4946 32.6954 33.8388C30.4305 36.183 27.3585 37.5 24.1553 37.5C20.9521 37.5 17.8801 36.183 15.6151 33.8388C13.3501 31.4946 12.0776 28.3152 12.0776 25C12.0776 21.6848 13.3501 18.5054 15.6151 16.1612C17.8801 13.817 20.9521 12.5 24.1553 12.5ZM24.1553 17.5C22.2334 17.5 20.3902 18.2902 19.0312 19.6967C17.6722 21.1032 16.9087 23.0109 16.9087 25C16.9087 26.9891 17.6722 28.8968 19.0312 30.3033C20.3902 31.7098 22.2334 32.5 24.1553 32.5C26.0772 32.5 27.9204 31.7098 29.2794 30.3033C30.6384 28.8968 31.4018 26.9891 31.4018 25C31.4018 23.0109 30.6384 21.1032 29.2794 19.6967C27.9204 18.2902 26.0772 17.5 24.1553 17.5Z"/>'
}
