import React from "react";
import ReactDOM from "react-dom/client";
import parse, { attributesToProps } from "html-react-parser";

export const reactToWebcomponent = (Comp) => {
  return class extends HTMLElement {
    connectedCallback() {
      const attrs = this.getAttributeNames().reduce((item, prop) => {
        const attr = prop.split(":");
        const value = this.getAttribute(prop);

        switch (attr[0]) {
          case 'x-on':
            item[attr[1]] = eval(value);
            break;
          case 'x-bind':
            item[attr[1]] = ["{", "["].includes(value[0]) ? JSON.parse(value) : value;
            break;
          default:
            item[attr[1]] = value;
        }

        return item
      }, {});

      let childrenHtml = "";
      for (let children of this.children)
        childrenHtml += children.outerHTML;
      
      const root = ReactDOM.createRoot(this);
      root.render(
        <Comp {...attrs}>{childrenHtml && parse(childrenHtml)}</Comp>
      );
    }
  };
}

export const stringTemplate = (template, options) => {
  return parse(template)
}