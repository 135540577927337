import React, { useEffect, useRef } from "react";

import Swiper from 'swiper';

export default ({ children, type }) => {
  const $el = useRef();

  const onPrev = (swiper) => {
    swiper.slidePrev(600, false);
  };

  const onNext = (swiper) => {
    swiper.slideNext(600, false);
  };

  const actionArrows = (arrow, action) => {
    switch (action) {
      case "active":
        arrow.classList.remove(
          "pointer-events-none",
          "grayscale",
          "opacity-50"
        );
				break;
      case "disabled":
        arrow.classList.add("pointer-events-none", "grayscale", "opacity-50");
				break;
      case "visible":
        arrow.classList.remove("!hidden");
				break;
      case "hidden":
        arrow.classList.add("!hidden");
				break;
    }
  };

	const onChanged = (swiper, $next, $prev) => {
		if (swiper.isBeginning && swiper.isEnd) {
      actionArrows($next, "hidden");
      actionArrows($prev, "hidden");
      return;
    }else {
      actionArrows($next, "visible");
      actionArrows($prev, "visible");
		}

    if (swiper.isBeginning) actionArrows($prev, "disabled");
    else actionArrows($prev, "active");

    if (swiper.isEnd) actionArrows($next, "disabled");
    else actionArrows($next, "active");
  };

  useEffect(() => {
    const $element = $el.current;
    const $dataSlide = $element.querySelector(".swiper");
    const $prev = $element.querySelector(".prev");
    const $next = $element.querySelector(".next");

    if ($dataSlide) {
      const swiper = new Swiper($dataSlide, {
        slidesPerView: 1,
        slidesPerGroup: 1,
        breakpoints: {
          768: {
            slidesPerView: 2,
            slidesPerGroup: 2,
          },
          1024: {
            slidesPerView: 3,
            slidesPerGroup: 3,
          },
        },
      });

      $prev.onclick = () => {
        $prev.classList.add("pointer-events-none");
        onPrev(swiper);
      };

      $next.onclick = () => {
        $next.classList.add("pointer-events-none");
        onNext(swiper);
      };

      swiper.on("resize", ($swiper) => {
        onChanged($swiper, $next, $prev);
      });

      swiper.on("transitionEnd", ($swiper) => {
        onChanged($swiper, $next, $prev);
      });
    }
  }, [$el]);

  return (
    <div ref={$el} className="overflow-hidden">
      {children}
    </div>
  );
};
