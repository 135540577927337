
import { reactToWebcomponent } from './js/utils/index.js';

import Icon from './js/components/Icon.js'
import Banner from './js/components/Banner.js'
import ProductsSlide from "./js/components/ProductsSlide.js"
import Locations from "./js/components/Locations.js"
import Header from "./js/components/Header.js"

import AOS from "aos"
import 'aos/dist/aos.css'; 
import 'swiper/css';

customElements.define("x-icon", reactToWebcomponent(Icon));
customElements.define("x-banner", reactToWebcomponent(Banner));
customElements.define("x-products-slide", reactToWebcomponent(ProductsSlide));
customElements.define("x-locations", reactToWebcomponent(Locations));
customElements.define("x-header", reactToWebcomponent(Header));

window.addEventListener('load', () => {
  AOS.init({
    offset: 280,
    duration:600,
    anchorPlacement: "top-center",
    once: true
  });
  document.body.classList.remove('invisible');
})