import React, { useState, useEffect, lazy } from "react";
import Accordion from "./Accordion.js";
// import Map from "./Map.js";
const Map = lazy(() => import("./Map.js"));
const Locations = ({ list, googlemapsapikey }) => {
  const [items, setItems] = useState([]);
  const [marks, setMarks] = useState([]);
  const [focus, setFocus] = useState([]);
  const [zoom, setZoom] = useState(14);

  const onFilterName = (item) => {
    let name = item.services[0].name;
    const nombre = item.nombre.replace(/altoque/gi, "");
    name += ` - ${nombre.trim()}`;
    return name; 
  }

	const onLocationMap = (item) => {
    return {
			position: { lat: Number(item.latitud), lng: Number(item.longitud) },
      servicios: item.services,
			nombre: onFilterName(item),
			horario: item.horario,
			direccion: `${item.direccion} - ${item.ciudad}, ${item.departamento}`,
			telefono: item.telefono
		}
  };

  const onInfoMap = (item) => {
    return {
      titulo: item.tipoRegistro,
      locations: [onLocationMap(item)],
    };
  };

  useEffect(() => {
    const result = [];
		const locations = [];
    list.forEach((item) => {
      const typeItem = result.find(
        (i) => i?.titulo?.toLowerCase() == item.tipoRegistro.toLowerCase()
      );
      if (typeItem) {
				typeItem.locations.push(onLocationMap(item));
      } else {
        result.push(onInfoMap(item));
      }
			locations.push(onLocationMap(item));
    });
    setFocus(locations[0]);
    setItems(result);
    setMarks(locations);

  }, []);

  const onChange = (item) => {
    setFocus(item);
    setZoom(14);
  };

  return (
    <div className="grid gap-y-5 lg:grid-cols-2">
      <div data-aos="fade-right" className="flex flex-col gap-16">
        {items.map((item, index) => (
          <Accordion key={index} {...item} onChange={onChange}></Accordion>
        ))}
      </div>
      <div
        data-aos="fade-left"
        className="sticky top-0 right-0 -mx-5 lg:mx-0 h-[40vh] lg:h-screen order-first lg:order-1 z-10"
      >
        <Map
          zoom={zoom}
          googleMapsApiKey={googlemapsapikey}
          setZoom={setZoom}
          marks={marks}
          focus={focus}
          onChange={onChange}
        ></Map>
      </div>
    </div>
  );
};
export default Locations;
