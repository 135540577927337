import React, {useRef, useEffect} from "react"
const AccordionContent = ({children, open}) => {
  const $content = useRef();
  useEffect(() => {
    const $this = $content.current;
    $this.style.height = open ? $this.scrollHeight + "px" : 0;
  }, [$content, open])
  return (
    <dd ref={$content} className={`h-0 overflow-hidden transition-[height] duration-500`}>
      {children}
    </dd>
  )
}
export default AccordionContent;