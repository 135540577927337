import React, {useState, useEffect} from "react"
import AccordionContent from "./AccordionContent.js"
import Icon from "./Icon.js"
const Accordion = ({titulo, locations, onChange}) => {
  const [state, setState] = useState([]);

  useEffect(() => {
    setState(locations.map((i, index) => (!Boolean(index))));
  }, []);

  const onCollapse = (item, index) => {
    const result = state.map((item, i) => (i === index));
    setState(result);
    onChange(item);
  }

  return (
    <div className="flex flex-col gap-6">
      {/* <h3 className="font-['Barley_Rough'] text-cardinal-600 text-2xl">{titulo}</h3> */}
      <div>
        {locations.map((item, index) => (
          <dl className="border-b boder-[#B4B4B4]" key={`accordion-item-${index}`}>
            <dt data-testid="accordion" onClick={() => onCollapse(item, index)} className="py-5 lg:pr-10 text-xl lg:text-[28px] text-cardinal-600 font-bold leading-tight cursor-pointer flex gap-5 items-center">
              <span className="flex-grow uppercase">
                {item.nombre}
              </span>
              <span className={`block w-5 transition duration-500 ${state[index] ? "rotate-180": ""}`}>
                <Icon icon="arrowDown"></Icon>
              </span>
            </dt>
            <AccordionContent open={state[index]}>
              <div className="pb-5 lg:pr-10">
                <ul className="flex flex-col gap-2">
                  <li>{item.direccion}</li>
                  <li className="font-bold">{item.horario}</li>
                </ul>
                <ul className="mt-5 flex flex-col gap-3">
                  <li className="font-semibold">{item.telefno}</li>
                  {/* <li>
                    <a href={"https://rappi.app.link/Sbarro"} target="_blank" className="block font-['Barley_Rough'] uppercase text-xl leading-none text-white p-2.5 lg:p-5 bg-cardinal-600 text-center">
                      Pide rappi
                    </a>
                  </li> */}
                </ul>
              </div>
            </AccordionContent>
          </dl>
        ))}
      </div>
    </div>
  )
}
export default Accordion;