import React, { useState, useEffect } from "react";
import Icon from "./Icon.js";

const Header = ({ brand = false, menu = [], socialmedia = [], link = "#" }) => {
  const [open, setOpen] = useState(false);
  const [active, setActive] = useState();

  useEffect(() => {
    setActive(window.location.pathname);

    const $body = document.body;

    if (open) $body.classList.add("overflow-hidden");
    else $body.classList.remove("overflow-hidden");
  }, [open]);

  return (
    <>
      <header className="absolute top-0 inset-x-0 z-50 py-5 pointer-events-none">
        <figure data-aos="fade-down" className=" flex justify-center">
					{brand && 
						<a
							href={link}
							data-testid="link"
							className="pointer-events-auto"
						>
							<img
								loading="lazy"
								src={brand}
								alt="Logo"
								className="w-16 md:w-auto"
							/>
						</a>
					}
        </figure>
        <div className="content-wrapper inset-x-0 top-0 absolute flex justify-end z-40">
          <button
            data-testid="open"
            onClick={() => setOpen(true)}
            className="pointer-events-auto mt-5 w-10 md:w-12 bg-cardinal-600 text-white rounded-full p-2 md:p-2.5"
            type="button"
            title="Menu"
          >
            <Icon icon="bars"></Icon>
          </button>
        </div>
        <div
          data-testid="overlay"
          onClick={() => setOpen(false)}
          className={`${
            open
              ? "opacity-50 pointer-events-auto"
              : "opacity-0 pointer-events-none"
          } transition duration-500 fixed inset-0 bg-black z-40 cursor-pointer`}
        ></div>
        <div
          className={`${
            open ? "translate-x-0" : "translate-x-full"
          } panel-menu pointer-events-auto`}
        >
          <button
            data-testid="close"
            onClick={() => setOpen(false)}
            className="fixed top-5 right-5 z-40 w-10 md:w-12 bg-white p-2 md:p-2.5 text-cardinal-600 rounded-full"
            type="button"
            title="Cerrar"
          >
            <Icon icon="xmark"></Icon>
          </button>
          <ul className="flex flex-col gap-10 mt-10 font-barley text-white text-3xl md:text-4xl leading-none">
            {menu.map((item, index) => (
              <li data-testid="link" key={`link-${index}`}>
                <a
                  href={item.link}
                  title={item.title}
                  className={`relative item-menu ${
                    active == item.link ? "active" : ""
                  }`}
                >
                  {item.title}
                  <span className="link-active"></span>
                </a>
              </li>
            ))}
          </ul>
          {Boolean(socialmedia.length) && (
            <div className="mt-auto items-center text-2xl text-white flex gap-5 font-[Trade_Gothic_Lt_Std]">
              {socialmedia.map((item, index) => (
                <a
                  data-testid="link"
                  href={item.link}
                  key={`social-media-${index}`}
                  className="w-10 md:w-14"
                  title={item.icon}
                >
                  <Icon icon={item.icon}></Icon>
                </a>
              ))}
              <span className="origin-left scale-x-[0.81] font-bold">
                síguenos
              </span>
            </div>
          )}
        </div>
      </header>
    </>
  );
};
export default Header;
